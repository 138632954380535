.startup {
  height: 100%;
  width: 100%;
  margin-top: 5rem;
  // font-family: "rajahn";
  &__intro {
    height: 100%;
    width: 100%;
    &-container {
      width: 70%;
      margin: auto;
      text-align: center;

      @include respond(tab-land) {
        width: 80%;
      }

      @include respond(tab-port) {
        width: 90%;
      }
      @include respond(phone) {
        width: 95%;
      }

      h1 {
        margin-bottom: 2rem;
      }
    }

    &-btns {
      margin: auto;
      margin-top: 3rem;
      display: flex;
      justify-content: center;
    }
  }

  &__areas {
    width: 100%;
    position: relative;
    margin-top: 8rem;

    @include respond(tab-port) {
      margin-top: 5rem;
    }
    &-container {
      @include container;
      padding: 8rem 0;
      @media screen and (max-width: 1600px) {
        width: 90% !important;
      }
    }

    &-box {
      // display: grid;
      // grid-template-columns: repeat(4, 1fr);
      // grid-template-rows: repeat(5, 1fr);
      // row-gap: 2.833rem;
      // column-gap: 8px;
      display: flex;
      justify-content: center;
      gap: 8px;

      // flex-wrap: wrap;
      overflow: hidden;
      @media screen and (max-width: 1400px) {
        // grid-template-columns: repeat(3, 1fr);
        // grid-template-rows: max-content repeat(3, 1fr);
      }

      @media screen and (max-width: 800px) {
        // grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 625px) {
        flex-wrap: wrap;
      }

      @media screen and (max-width: 480px) {
        grid-template-columns: repeat(1, 100%);
        row-gap: 50px;
      }
    }

    &-header {
      grid-column: 3/ -1;
      justify-self: center;
      width: 70%;
      text-align: center !important;

      @media screen and (max-width: 1400px) {
        width: 80%;
        grid-column: 1 / -1;
        grid-row: 1 / 2;
        text-align: center;
        justify-self: stretch;
        width: 100%;
      }

      // @media screen and (max-width: 1400px) {
      // }
    }

    &-item {
      position: relative;
      box-shadow: 0px 4px 14px rgba(151, 161, 184, 0.1);
      border-radius: 6px;
      transition: all 0.3s ease-in;
      height: 391px;
      // width: 294px;
      margin-bottom: 8px;

      &:hover {
        transform: translateY(-0.5rem) scale(1.05);
      }
      @media screen and (max-width: 1400px) {
        align-self: flex-start !important;
      }

      @media (max-width: 480px) {
        grid-column: 1 / -1 !important;
        justify-self: center;
        display: flex;
        justify-content: center;
      }
      &-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 2rem;

        @media (max-width: 480px) {
        }
        & > h1 {
          margin-bottom: 1.5rem;
        }

        & > div {
          width: 5rem;
          height: 5rem;
          border-radius: 6px;
          margin-bottom: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      @media screen and (max-width: 625px) {
        height: auto;
      }
      // &-1 {
      //   grid-column: 1/2;
      //   grid-row: 1 /3;
      //   align-self: flex-end;

      //   @media screen and (max-width: 1400px) {
      //     width: 395px;
      //     align-self: flex-start;
      //   }
      //   @media screen and (max-width: 800px) {
      //     grid-column: 1 /2;
      //     grid-row: 2/3;
      //   }

      //   @media screen and (max-width: 480px) {
      //     grid-row: 2/3;
      //   }
      // }

      // &-2 {
      //   grid-column: 2 /3;
      //   align-self: center;
      //   grid-row: 1 /3;
      //   @media screen and (max-width: 1400px) {
      //     align-self: flex-start;
      //     grid-row: 2 / 3;
      //   }

      //   @media screen and (max-width: 800px) {
      //     grid-column: 2/3;
      //     grid-row: 2/3;
      //   }
      //   @media screen and (max-width: 480px) {
      //     grid-row: 3/4;
      //   }
      // }

      // &-3 {
      //   grid-row: 3 / 6;
      //   align-self: center;
      //   @media screen and (max-width: 1400px) {
      //     align-self: flex-start;
      //     grid-row: 2 / 3;
      //     grid-column: 3 /-1;
      //   }

      //   @media screen and (max-width: 800px) {
      //     grid-row: 3 /4;
      //     grid-column: 1 /2;
      //   }
      //   @media screen and (max-width: 480px) {
      //     grid-row: 4/5;
      //   }
      // }

      // &-4 {
      //   grid-row: 2 / -1;
      //   align-self: center;
      //   @media screen and (max-width: 1400px) {
      //     grid-row: 3/ 4;
      //     grid-column: 1 /2;
      //   }
      //   @media screen and (max-width: 800px) {
      //     grid-column: 2/3;
      //     grid-row: 3 /4;
      //   }

      //   @media screen and (max-width: 480px) {
      //     grid-row: 5/6;
      //   }
      // }

      // &-5 {
      //   grid-row: 2 / 4;
      //   grid-column: 3 / 4;
      //   width: 100%;
      //   @media screen and (max-width: 1400px) {
      //     grid-row: 3/ 4;
      //     grid-column: 2 / 3;
      //   }
      //   @media screen and (max-width: 800px) {
      //     grid-row: 4 /5;
      //     grid-column: 1/2;
      //   }
      //   @media screen and (max-width: 480px) {
      //     grid-row: 6/7;
      //   }
      // }

      // &-6 {
      //   grid-row: 3 /5;
      //   align-self: flex-start;
      //   width: 100%;

      //   @media screen and (max-width: 1400px) {
      //     grid-row: 3/ 4;
      //     grid-column: 3 / -1;
      //   }
      //   @media screen and (max-width: 800px) {
      //     grid-row: 4 /5;
      //     grid-column: 2/3;
      //   }
      //   @media screen and (max-width: 480px) {
      //     grid-row: 7/8;
      //   }
      // }

      // &-7 {
      //   grid-column: 3 / 4;
      //   grid-row: 4 / -1;
      //   align-self: center;

      //   @media screen and (max-width: 1400px) {
      //     grid-row: 4 / -1;
      //     grid-column: 1/2;
      //     width: auto !important;
      //   }
      //   @media screen and (max-width: 800px) {
      //     grid-row: 5/-1;
      //     grid-column: 1/2;
      //   }

      //   @media screen and (max-width: 480px) {
      //     grid-row: 8/9;
      //   }
      // }
    }
  }

  &__values {
    background: #773dd3;
    background-image: url("../images/svg/pattern.svg");
    position: relative;
    margin-top: 10rem;
    width: 1200px;
    margin: auto;
    border-radius: 16px;

    @media screen and (max-width: 1400px) {
      width: 90%;
    }
    &-container {
      @include container;
      padding: 6rem 0;
    }

    &-header {
      margin-bottom: 3rem;
      display: flex;
      flex-direction: column;
    }

    &-items {
      display: grid;
      grid-template-columns: repeat(3, 30%);
      column-gap: 48px;
      row-gap: 32px;
      @media (max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 1rem;
      }
    }
  }

  &__timeline {
    width: 100%;
    margin: 5rem 0;
    &-container {
      @include container;

      padding: 0 0 4rem 0;
    }

    &-header {
      margin-bottom: 1rem;
      text-align: center !important;
    }

    &-list {
      margin-top: 3rem;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      justify-content: space-between;
      @media screen and (max-width: 800px) {
        grid-template-columns: repeat(1, 100%);
      }
    }

    &-item {
      display: flex;
      flex-direction: column;

      @media (max-width: 800px) {
        display: none;
      }

      &:first-child {
        grid-column: 1 /3;
        & > p {
          background: #773dd3 !important;
          color: #ffffff;
          @media screen and (max-width: 800px) {
            background-color: transparent !important;
            color: #575a65;
          }
        }
      }

      &:nth-child(3) {
        grid-column: 5 / -1;
      }

      &:nth-child(4) {
        grid-column: 2/3;
      }

      &:nth-child(5) {
        grid-column: 4 /-1;
      }

      &:nth-child(4),
      &:nth-child(5) {
        & > div > span::before {
          top: -9px !important;
        }
      }

      & > p {
        background: rgba(119, 61, 211, 0.1);
        padding: 1rem 1.5rem;
        width: fit-content;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.03em;
        color: #773dd3;
        margin-bottom: 0 !important;
      }
    }
    &-meta {
      display: flex;
      position: relative;
      & > span {
        width: 3px;
        height: 100px;
        display: inline-block;
        background: rgba(119, 61, 211, 0.1);
        position: relative;

        &::before {
          position: absolute;
          bottom: -12px;
          left: -9px;
          width: 20px;
          height: 20px;
          content: "";
          background: #773dd3;
          border: 4px solid #f3efff;
          border-radius: 50%;

          @media screen and (max-width: 800px) {
            top: 0;
          }
        }
      }

      & > p {
        font-weight: 500;
        font-size: 1rem;
        line-height: 2rem;
        display: inline;
        letter-spacing: 0.03em;

        color: #773dd3;
        margin: 0 0 0 2rem;
        position: absolute;
        top: 30px;
      }
    }
  }

  &__involve {
    // background: #1f1035;
    // background: #fff;

    margin-top: 5rem;
    &-container {
      @include container;
      // padding: 12rem 0;
      padding: 2.5rem 0;
      width: 100%;

      // & > div:first-child {
      //   margin-bottom: 4rem;
      // }
    }

    &-items {
      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
      display: flex;
      justify-content: center;
      gap: 8px;
      overflow: hidden;

      @media screen and (max-width: 800px) {
        // grid-template-columns: repeat(2, 1fr);
        // row-gap: 5.3rem;
        flex-wrap: wrap;
      }

      @media screen and (max-width: 600px) {
        // grid-template-columns: repeat(1, 1fr);
        // row-gap: 10.3rem;
      }
    }

    &-list {
      // &:not(:last-child) {
      //   border-right: 1px solid white;
      // }

      // &:not(:first-child) > div {
      //   margin: auto;
      //   @media screen and (max-width: 600px) {
      //     margin: 0;
      //   }
      // }

      &-container {
        width: 393px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 418px;
        background-color: #773dd3;
        background-image: url("../images/svg/stroke.svg");
        border-radius: 16px;
        padding: 67.5px;
        a {
          display: flex;
          align-self: center;
          justify-content: center;
          text-decoration: none;
        }
        @include respond(tab-land) {
          width: 85%;
        }

        @include respond(tab-port) {
          width: 95%;
        }

        @media screen and (max-width: 800px) {
          // align-items: center;
          text-align: center;
          padding: 5rem 53px;
          max-width: 393px;
          margin: auto;
          height: 343px;
        }
        @media screen and (max-width: 450px) {
          // width : 95%;
          // margin: 0;
        }
        & > h1 {
          margin-bottom: 3rem;
          color: white;
          font-weight: 500;
          font-size: 2rem;
          line-height: 3.125rem;
          text-align: center !important;
          letter-spacing: 0.01em;
          span {
            color: #ffc823;
          }
        }

        & > button {
          align-self: center;
          background-color: #fff !important;
          color: #773dd3;
        }
        button {
          width: 173px !important;
        }
      }

      @media screen and (max-width: 800px) {
        &:nth-child(2) {
          border-right: none;
        }

        &:last-child {
          grid-column: 1/-1;
          width: 50%;
          margin: auto;
        }
      }
      @media screen and (max-width: 600px) {
        border: 1px solid #ffffff;
        border-radius: 10px;
        &:last-child {
          grid-column: 1/-1;
          width: 100%;
        }

        &:nth-child(2) {
          border-right: 1px solid #ffffff;
        }
      }
    }
  }

  &__subscribe {
    margin-top: 10rem;
  }
}

.mobile {
  // height: 130px;
  position: relative;
  display: none;
  margin-bottom: 16px;

  @media screen and (max-width: 800px) {
    display: block;
  }
  &:not(:last-child) > div > span::before {
    // width: 3px;
    // height: 114px;
    // background-color: hsl(0, 0%, 98%);
    // left: 8.3px;
    // top: 46px;
    // content: "";
    // position: absolute;
  }
  &__container {
    display: flex;
    overflow-x: hidden;
    span {
      align-self: flex-end;
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 50%;
      background: #773DD3;
      border: 4px solid #F3EFFF;
      box-sizing: border-box;
    }
  }
  &__meta {
    margin-left: 4rem;
    width: 100%;
    p:first-of-type {
      font-family: Graphik;
      font-size: 12px;
      letter-spacing: 0.03em;
      color: #575a65;
      margin-bottom: 0;
    }

    p:last-of-type {
      margin-top: 1rem;
      margin-bottom: 0;
      font-family: Graphik;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.03em;
      color: #773DD3;
    }
  }
}
.value {
  &__container {
    @media (max-width: 800px) {
      padding: 0 !important;
    }
    h2 {
      color: #f5f5f5 !important;
    }
  }
  &__content {
    @media (max-width: 800px) {
      margin-top: 24px !important;
    }
    h1 {
      color: #fff !important;
    }
    p {
      color: #fff !important;
    }
  }
}
.hide {
  @media screen and (max-width: 800px) {
    display: none;
  }
}
.hide2 {
  display: none;
  @media screen and (max-width: 800px) {
    display: flex;
  }
}
