.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color:rgba(#f0f0f0, .8);
  &__container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__box {
    background-color: $color-white;
    width: 497px;
    height: 347px;
    margin: auto;
    text-align: center;
    // padding: 10rem 0;
    position: relative;

    @include respond(tab-land){
      width: 70%;
    }
    @include respond(tab-port) {
      width: 95%;
    }
  }

  &__content {
    margin-top: 3rem;
    font-family: "Montserrat", sans-serif;
    @media screen and (max-width: 600px) {
      margin-top: 1rem;
    }
    h6:first-of-type,
    p {
      font-size: 1.125rem;
      line-height: 22px;
      letter-spacing: 0.2em;
      color: #666666;
      text-transform: uppercase;
    }

    h6:last-of-type {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 2.5rem;
      line-height: 49px;

      letter-spacing: -0.05em;
      margin-bottom: 1rem;

      color: #783dd3;
    }
    p {
      width: 90%;
      margin: auto;
      line-height: 34px;
      margin-bottom: 2rem;
    }

    a {
      width: 30%;
      margin: auto;
      @include respond(tab-port){
        width: 50%;
      }
    }
  }
  &__cancel {
    position: absolute;
    top: 1.875rem;
    right: 1.875rem;
    height: 1.875rem;
    width: 1.875rem;
    border-radius: 50%;
    background-color: $color-main;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 41px;
      text-align: center;
      font-variant: small-caps;

      color: #ffffff;
    }
  }
}
