.header {
  width: 100%;
  @media (min-width: 1920px) {
    max-width: 1900px;
    margin: 0 auto;
  }
  // position: fixed;
  // top: 0;
  // left: 0;
  background-color: #ffffff;
  // background-color:#5726a6;
    position: relative;
    z-index: 10000;
  &__container {
    @include container;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    @media screen and (max-width: 800px) {
      justify-content: space-between;
    }
  }

  &__img__box {
    // flex-basis: 3.125rem;
    width: 150px;
    display: block;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__contact {
    margin-left: 10rem;
    align-self: center;
    display: flex;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
    @media screen and (max-width: 1550px) {
      margin-left: 6rem;
    }

    @media screen and (max-width: 1000px) {
      margin-left: 4rem;
    }
    @media screen and (max-width: 800px) {
      display: none;
    }
    span {
      margin-right: 0.8rem;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 25px;

      color: #773dd3;
    }

    div {
      height: 40px;
      width: 40px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__hamburger {
    display: none;
    height: 35px;
    width: 35px;
    cursor: pointer;
    background: rgba(243, 239, 255, 0.5);
    border-radius: 3px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 800px) {
      display: flex;
    }
  }
}
