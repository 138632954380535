.tri {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-bottom: 30px solid #e9edfa;
}

.about {
  overflow-x: hidden;
  @media (min-width: 1920px) {
    max-width: 1920px;
    margin: 0 auto;
  }
  &_top {
    background: rgba(161, 148, 186, 0.1);
    position: relative;

    &-text {
      padding: 64px 120px 15vw;
      @media (max-width: 1280px) {
        padding: 64px 70px 15vw;
      }
      @media (max-width: 1024px) {
        padding: 64px 32px 15vw;
      }
      @media (max-width: 600px) {
        padding: 64px 16px 15vw;
      }
      h1 {
        font-size: 64px;
        font-weight: 700;
        color: #151F10;
        line-height: 81.66px;
        letter-spacing: 1%;
        width: 65%;
        @media (max-width: 1024px) {
          width: 75%;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
        @media (max-width: 600px) {
          font-size: 32px;
          line-height: 40.83px;
          margin: 0 15px;
        }
      }
      p {
        font-size: 18px !important;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0%;
        color: #575A65;
        @media (max-width: 600px) {
          // font-size: 2rem;
          margin: 0 15px;
        }
      }
    }
    &-images {
      display: flex;
      position: absolute;
      right: 0;
      left: 0;
      bottom: -20vw;
      box-sizing: border-box;
      @media (max-width: 700px) {
        bottom: -40vw;
      }
      &-item {
        width: 20vw;
        height: 30vw;
        border-radius: 15px;
        overflow: hidden;
        margin: 10px;
        @media (max-width: 700px) {
          width: 50%;
          height: 50vw;
        }

        .item-inside {
          height: 49%;
          width: 100%;
          margin-bottom: 10px;
          border-radius: 15px;
        }
        .item-inside2 {
          height: 49%;
          background: blue;
          width: 100%;
          margin-bottom: 10px;
          border-radius: 15px;
        }
        .about1 {
          // background: url("../images/website/IMG_8297-2.JPG");
          background: url("../images/website/newabout1.png");
          background-size: cover;
          background-repeat: no-repeat;
        }
        .about2 {
          // background: url("../images/website/IMG_8259-2.JPG");
          background: url("../images/website/newabout2.png");
          background-size: cover;
          background-repeat: no-repeat;
        }
        .about3 {
          height: 100%;
          @media (max-width: 700px) {
            height: auto;
            width: 100%;
          }
        }
        .about4 {
          height: 100%;
        }
        .about5 {
          // background: url("../images/website/IMG_8290-2.JPG");
          background: url("../images/website/newabout5.png");
          background-size: cover;
          background-repeat: no-repeat;
        }
        .about6 {
          // background: url("../images/website/IMG_7241.jpg");
          background: url("../images/website/newabout6.png");
          background-size: cover;
          background-repeat: no-repeat;
        }
        .about7 {
          // background: url("../images/website/IMG_8359.JPG");
          background: url("../images/website/newabout7.png");
          background-size: cover;
          background-repeat: no-repeat;
        }
        .about8 {
          // background: url("../images/website/IMG_8403-2.JPG");
          background: url("../images/website/newabout8.png");
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
      .item-hide {
        @media (max-width: 700px) {
          display: none;
        }
      }
    }
  }
  &_space-div{
    height: 35vh; 
    background: #FAF8FE;
    @media (max-width: 1024px) {
      height: 23vh;
    }
    @media (max-width: 1280px) {
      height: 30vh;
    }
  }
  &_slide {
    margin-top: 50px;
    @media (max-width: 700px) {
      margin-top: 90px;
    }
    p {
      font-size: 1rem;
      color: #575a65;
      @media (max-width: 600px) {
        font-size: 2rem;
      }
    }
    h2 {
      font-size: 2.5em;
      @media (max-width: 600px) {
        font-size: 3.7rem;
      }
    }

    .item-hide {
      @media (max-width: 700px) {
        display: none;
      }
    }

    &-impact {
      margin: 50px 15px 0;
      box-sizing: border-box;
      &-view {
        @media (min-width: 750px) {
          display: flex;
        }
        img {
          margin: 5px;
          width: 100%;
          height: auto;
          @media (min-width: 750px) {
            width: 33%;
          }
        }
      }
    }
  }
  &_define {
    background: rgba(207, 185, 249, 0.1);
    padding: 70px 120px;
    margin: 0px 0 100px;
    @media (max-width: 1280px) {
      padding: 70px;
    }
    @media (max-width: 1024px) {
      padding: 32px;
    }
    @media (max-width: 600px) {
      padding: 16px;
    }
    .sub-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 48px;
      letter-spacing: 3%;
      color: #575a65;
      // @media (max-width: 600px) {
      //   font-size: 2rem;
      // }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 3%;
      color: #575a65;
      @media (max-width: 600px) {
        font-size: 2rem;
      }
    }
    h2 {
      color: #151F10;
      font-weight: 700;
      line-height: 50px;
      font-size: 38px;
      letter-spacing: 1%;
      // @media (max-width: 600px) {
      //   font-size: 3.7rem;
      // }
    }
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 30.62px;
      letter-spacing: 3%;
      margin: 15px 0;
      color: #151F10;
      @media (max-width: 600px) {
        font-size: 2.7rem;
      }
    }

    &-cards {
      @media (min-width: 600px) {
        display: flex;
      }
      &-box1 {
        background: white;
        padding: 20px;
        margin: 20px 0px 10px;
        border-radius: 10px;
        @media (min-width: 600px) {
          width: 33%;
          margin: 10px;
        }
      }
    }
  }

  &-cards {
    display: flex;
    &-box1 {
      width: 33%;
      background: white;
      padding: 20px;
      margin: 10px;
      border-radius: 10px;
    }
  }
  &_work {
    padding: 0 120px;
    @media screen and (max-width:1280px){
      padding: 0 70px;
    }
    @media screen and (max-width:1024px){
      padding: 0 32px;
    }
    @media screen and (max-width:600px){
      padding: 0 16px;
    }
    h2 {
      font-size: 38px;
      font-weight: 700;
      letter-spacing: 1%;
      color: #151F10;
      line-height: 50px;
      @media (max-width: 600px) {
        font-size: 24px;
        line-height: 30.62px;
      }
    }
    P:last-of-type{
      font-size: 15px !important;
      font-weight: 500;
      line-height: 15.31px;
      letter-spacing: 0%;
    }
    .options {
      color: $color-main;
      margin: 3px 0 0 7px;
    }
    p {
      font-size: 16px;
      line-height: 48px;
      letter-spacing: 3%;
      color: #575A65;

      // @media (max-width: 600px) {
      //   font-size: 3.2rem;
      // }
    }
  }
}
